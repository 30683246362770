import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "vue-select/dist/vue-select.css";
import "@/scss/styles.scss";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { PiniaSharedState } from "pinia-shared-state";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import Vue3Toastify, { type ToastContainerOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VueGtag from "vue-gtag";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(PiniaSharedState({ enable: true, initialize: true, type: "native" }));

const app = createApp(App);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn: "https://fc93271546f920c846de76f6afbff672@sentry.onix.team/216",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "onix-systems-nexusmedx-frontend.staging.onix.ua/",
          /^\//,
        ],
      }),
    ],
    tracesSampleRate: 1.0,
    environment: "development",
    logErrors: true,
  });
}

app
  .use(pinia)
  .use(router)
  .use(VueGtag, {
    config: {
      id: process.env.VUE_APP_GTAG_MEASUREMENT_ID,
    },
  })
  .use(Vue3Toastify, {
    autoClose: 3000,
    clearOnUrlChange: false,
    newestOnTop: true,
  } as ToastContainerOptions)
  .use(VueTelInput)
  .mount("#app");
