import axiosClient from "./index";
import { ApiClientResponse } from "@/types/api/ApiClientResponse";
import { IActivity } from "@/types/entities/IActivity";
import { IActivityResponse } from "@/types/api/IActivityResponse";
import { ICourseProcessing } from "@/types/entities/ICourseProcessing";
import { ICoursePreview } from "@/types/entities/ICoursePreview";
import { ICoursesListParams } from "@/types/api/ICoursesListParams";
import { IActivityCompletedDataBody } from "@/types/api/IActivityCompletedDataBody";
import { IListResponseBody } from "@/types/api/IListResponseBody";
import { IFilter } from "@/types/entities/IFilter";
import { ICertificate } from "@/types/entities/ICertificate";

export default {
  getActivityById(courseId: number): Promise<ApiClientResponse<IActivity>> {
    return axiosClient.get(`courses/${courseId}/activity`);
  },
  getFollowUpById(courseId: number): Promise<ApiClientResponse<IActivity>> {
    return axiosClient.get(`courses/${courseId}/follow-up`);
  },
  getPreviewById(courseId: number): Promise<ApiClientResponse<ICoursePreview>> {
    return axiosClient.get(`courses/${courseId}/preview`);
  },
  getReviewById(courseId: number): Promise<ApiClientResponse<ICoursePreview>> {
    return axiosClient.get(`courses/${courseId}/review`);
  },
  setProcessingById(payload: {
    courseId: number;
    data: ICourseProcessing;
  }): Promise<ApiClientResponse<IActivityResponse>> {
    return axiosClient.post(
      `courses/${payload.courseId}/processing`,
      payload.data
    );
  },
  setCompletedDataById(payload: {
    courseId: number;
    completedData: {
      claims: IActivityCompletedDataBody[];
    };
  }): Promise<ApiClientResponse<{ certificates: ICertificate[] }>> {
    return axiosClient.post(
      `courses/${payload.courseId}/completed-data`,
      payload.completedData
    );
  },
  getListOfCourses(
    params: ICoursesListParams
  ): Promise<ApiClientResponse<IListResponseBody<ICoursePreview>>> {
    return axiosClient.get("courses", { params });
  },
  getListOfCredits(params: {
    page: number;
    per_page: number;
    name?: string;
  }): Promise<ApiClientResponse<IListResponseBody<IFilter>>> {
    return axiosClient.get("credits", { params });
  },
  getListOfSpecialities(params: {
    page: number;
    per_page?: number;
    name?: string;
    is_trending?: boolean;
  }): Promise<ApiClientResponse<IListResponseBody<IFilter>>> {
    return axiosClient.get("specialities", { params });
  },
  getListOfSpecialitiesCropped(params: {
    page: number;
    per_page?: number;
    name?: string;
  }): Promise<ApiClientResponse<IListResponseBody<IFilter>>> {
    return axiosClient.get("specialities/option", { params });
  },
  getSuggestedCourses(
    params: ICoursesListParams
  ): Promise<ApiClientResponse<IListResponseBody<ICoursePreview>>> {
    return axiosClient.get("courses/suggested", { params });
  },
};
